nav .logo {
    width: 13.75rem;
}
nav .logo {
    margin-top: 3px;
}
nav .logo {
    width: 10.31rem;
}

.top-bar{
	background: $colorBackgroundSeven none repeat scroll 0 0;
}

header {

  background-color: get-color(gray, 1);

  .header-row {
    @include grid-row;
    max-width: 100%;
  }

  .header-columns{
    @include grid-column(12);
    padding: 0;
  }

  .header-line {
    display: none;
    background: #fff;
    color: #fff;
    border: none;
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
    height: 0.06rem;
    opacity: .2;
  }

}

.header-alert{
  background-color: #fff;
  text-align:center;
  padding: 0;
  font-size: .75rem;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  max-height: 0;
  &.activated {
    max-height: 8rem;
    padding: 1rem;
  }
  &.hidden {
    max-height: 0;
    padding: 0;
  }
}
nav {
  border-bottom: 1px solid $grey3;

  .logo {
    width: 10.31rem;
  }

  .sign-in-button {
    margin: 0 1.25rem;
    position: absolute;
    top: 0.55rem;
    right: 0;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightNormal;
    line-height: 2.15rem;
    color: $colorTextTwo;
  }

  a {
    text-decoration: none;

    .menu-title, .burger {
      display: inline-block;
      position: relative;
    }

    .burger-bar {
      background-color: $grey7;
      height: 0.13rem;
      width: 1.13rem;
      margin-top: 0.19rem;
      border-radius: 1rem;
    }

  }

  .triangle {
    display: none;
  }

  &.top-bar {
    height:3.75rem;
    .title-area{
      background: get-color(gray, 1);
      position: static;
    }
    .toggle-topbar{
      top: 0.3125rem;
    }

  }
  .top-bar-section {

    ul {
      background : get-color(gray, 1);
    }
    ul li {
      background: $white;
    }
    ul li > a{
      max-width: 20rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .has-dropdown > a:after {
      margin-top: -0.48rem;
    }

    .top-bar-link {

      &:hover {
        color: $colorTextOne;
        background: #fff;
      }
    }

    .sign-in,
    .join-now,
    .how-it-works {
      position: relative;

      ::after {
        display: block;
        margin-right: 1.04rem;
        margin-top: -0.48rem;
        position: absolute;
        top: 50%;
        right: 0;
      }

    }
    .join-now a {
      color: $colorBackgroundTen;
    }

    .right .dropdown li a.dropdown-link-color {
      font-weight: $fontWeightBold;

      &.two-line br {
        display: none;
      }
    }

    .dropdown .title:first-child h5 a {
      border: 0 !important;
    }

    .dropdown li.title h5 a,
    .dropdown li.title h5 a:hover,
    .dropdown li.title h5 a:active
    {
      color: $grey6;
      font-weight: $fontWeightNormal;
      font-size: $fontSizeSmall;
      /*background: asset-url('#{$theme_image_path}arrow_left_black.svg') no-repeat 0 15%;*/
      background-size: 1rem 1rem;
      padding-left: 1.5rem;

      &:before {
        content: " ";
      }

    }

    .dropdown li.parent-link a {
      color: $colorTextOne;
    }

  }

  &.top-bar .toggle-topbar a {
    font-size: $fontSizeSmall;
    color: $grey7;
    padding: 0 1rem;
  }

  &.top-bar .top-bar-section .small-dropdown-off a:after {
    border: inset 5px;
    border-color: rgba($colorBorderSix, 0.4) transparent transparent transparent;
    border-top-style: solid;
  }

  &.expanded {
    .top-bar-section .right ul.dropdown li.parent-link.show-for-small {
      display: none !important;  /* bad form, only temporary */
    }

    > .top-bar-section ul li {
      padding: 1rem 1rem 0;

      a {
        padding: 0 0 1rem;
        color: $grey7;
        border-bottom: 2px solid $colorBorderFive;
        font-weight: $fontWeightLight;
        font-size: 1.25rem;
        &:hover{
          color: $pink5;
        }
        .ellipsis{
          width: 330px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }


      .dropdown li {
        &:not(.title) {
          a {
            border-bottom: none;
            font-weight: $fontWeightLight;
            padding-bottom: 1rem;
            font-size: 1.25rem;
          }
        }
      }
    }

    .top-bar-section ul.dropdown .parent-link {
      display: none;
    }

    .top-bar-section .top-bar-link:after {
      line-height: 0;
      content: " ";
      background:asset-url('#{$theme_image_path}arrow_right_black.svg') no-repeat top right;
      background-size: 1rem 1rem;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
    }

    .toggle-topbar a {
      .burger-bar:nth-child(1) {
        transform: rotate(45deg) translate(4px, 3px);
        -ms-transform: rotate(45deg) translate(4px, 3px);
        -moz-transform: rotate(45deg) translate(4px, 3px);
        -webkit-transform: rotate(45deg) translate(4px, 3px);
      }

      .burger-bar:nth-child(2) {
        background-color: transparent;
      }

      .burger-bar:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -3px);
        -ms-transform: rotate(-45deg) translate(4px, -3px);
        -moz-transform: rotate(-45deg) translate(4px, -3px);
        -webkit-transform: rotate(-45deg) translate(4px, -3px);
      }

    }

  }

}
.reskin-promo{
  background-color: $orange4;
  height: 3.813rem;
  text-align: center;
  p{
    color: $white;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 0.875rem;
    padding-top: 1.2rem;
  }
}

/*  ==================================================
    Large Up
    ==================================================  */
nav {
    .right .dropdown li {
      &:first-child {
        border-top: 1px solid $colorBorderSeven;
      }
      &:last-child {
        border-bottom: 1px solid $colorBorderSeven;
      }
      border: 1px solid $grey3;
      border-bottom: 0;
    }
    .logo {
      width: 13.75rem;
    }

    .sign-in-button {
      width: 8.75rem;
      height: 2.5rem;
      top: 0.75rem;
      margin: 0 3.75rem 0 1.25rem;
      line-height: 2.25rem;
    }

    &.top-bar {
      height: 4.75rem;
      border-bottom: 1px solid $grey4;

      .name {
        a {
          margin-left: 2.35rem;
        }

        h1 {
          line-height: 2.9rem;
          margin-top: 5px;
        }

      }

      .top-bar-section {
        .mid-menu{
          margin-right: 0.5rem !important;
        }
        .has-dropdown > a:after {
          margin-top: -0.28rem;
        }

        a.top-bar-link {
          background : $colorBackgroundSeven;
          color: $grey7;
          font-size:1rem;
          font-weight:$fontWeightLight;
          padding: 0 0.8125rem;
          &:hover {
            color: $colorTextTwo;
            background: #fff;
          }
        }
        .join-now a{
          color:$pink5;
        }

        .triangle {
          background: asset-url('#{$theme_image_path}icon_triangle_gray.svg') no-repeat 0;
          width: 1.25rem !important;
          height: 0.75rem;
          border: 0 !important;
          position: relative;
          display: block;
          left: 50%;
          top: 0.06rem;
        }

        .small-dropdown-off a:after {
          border: none;
        }

        /* Show and hide animation of dropdown Starts */
        .has-dropdown > ul {
          visibility: hidden;
          opacity: 0;
          @include psp-transition(visibility, 0s, ease);
          @include psp-transition(opacity, 0.33s, ease);
        }

        .has-dropdown:hover > ul {
          visibility: visible;
          opacity: 1;
          @include psp-transition(opacity, .33s, ease, 0s);
        }
        /* Show and hide animation of dropdown Ends */

        .has-dropdown:hover > .dropdown {
          left: -50%;
          min-width: 14.88rem;
        }

        .has-dropdown.about:hover > .dropdown {
          min-width: 10rem;
        }

        .has-dropdown {

          position: relative;
          top: 0.20rem;

          &.legal {
            display: none;
          }

          > a:after {
            border: 0;
            background: asset-url('#{$theme_image_path}icon_arrow_down_thin_white.svg') 0.81rem 0.44rem;
            width: 0.81rem;
            height: 0.44rem;
          }
          a:hover:after{
            background: asset-url('#{$theme_image_path}icon_arrow_down_thin_pink.svg') 0.81rem 0.44rem;
          }

        }

        .has-dropdown > a:after {
          top: 1.75rem;
        }

        .right {
          margin-top:8px;
        }
        .logged-in{
          margin-right: 1.375rem;
        }
        .logged-out{
          margin-right: 2.3rem;
        }

        .right:first-child {
          a {
            padding-right: 3.75rem;
          }
        }

        .right .dropdown {
          margin-top: -0.75rem;

          &.my-account {
            max-width: 4.69rem;
            left: auto;
            right: 2px;
          }
          &.dropdown-invest {
            left: -94%;
          }

          &.dropdown-borrow {
            left: -84%;
          }

        }

        .right .dropdown li a.dropdown-link-color {
          background:$white;
          color: $grey7;
          &.two-line {
            line-height: 1.25rem;
            padding: 1.25rem;
          }
          &.two-line br {
            display: block;
          }
        }

        .right .dropdown li a.dropdown-link-color:hover {
          background: $pink5;
          color:#fff;
        }

        .right li:hover a {
          color: $pink5;
          cursor: pointer;
          background: #fff;
        }
        .right li.sign-in:hover a, .right li.join-now:hover a {
          color: $white;
        }
        .right .dropdown.about li {
          width: 11.88rem;
        }

        .right .dropdown.about {
          min-width: initial;
        }

      }

    }

    .top-bar-section {

      a.top-bar-link {
        line-height: 3.5rem !important;
      }

      .single-link {
        position: relative;
        top: 0.20rem;
      }

      .header-button-container { /* more bad form, ah foundation! */
        width: 8.75rem !important;
        height: 2.5rem !important;
        top: 0.6rem !important;
        text-align: center;
        border-radius: 0.25rem;

        &.has-dropdown {

          .top-bar-link:after {
            display: none;
          }

        }

        .top-bar-link {
          height: auto !important; /* bad form, refactor later to not use foundation's top-bar */
          padding: 0 !important;
          background: transparent !important;
          line-height: 2.25rem !important;
          color: $colorTextOne;
        }

        &.sign-in {
          background: transparent;
          border: 2px solid $grey7;

          &:hover {
            background: $pink5;
            border:2px solid $pink5;
          }

        }

        &.join-now {
          margin-left: 1.25rem;
          border: 2px solid $colorBackgroundTen;
          &:hover {
            background: $pink5;
            border: 2px solid $pink5;
          }

        }

      }

    }

  }

.error-alert-header {
  background-color: $colorBackgroundThirteen;
  color: $colorAttentionTwo;
}
