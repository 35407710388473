.mt20 {
  margin-top: 20px;
}

body, p {
  font-size: $fontSizeSmall;
}

.reveal-modal .close-reveal-modal {
  font-weight: $font-weight-normal;
}

.partner-section{
  padding-top: 2rem;
  h2 {
    font-weight: $fontWeightNormal;
  }
  .bottom-header-padding {
    margin-bottom: 0.5rem;
  }

  .partner-box-panel{
    border: 1px solid get-color(gray,4);
    margin-bottom: 20px;

    .row{
      padding: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .radio-button-row {
      padding: 0rem 0rem 0rem 0.5rem;
    }

    select {
      width: 40%;
    }

    label {
      color: #4d4d4d;
    }

    .border-bottom {
      border-bottom: 1px solid get-color(gray,4);
    }
    .branding-margin {
      margin-left:5rem;
      margin-right:1rem;
      margin-bottom:1rem;
    }

    .title{
      font-weight: $fontWeightNormal;
    }

    .content{
      font-weight: $fontWeightLight;
    }
  }

  .parameters-panel{
    margin-top: 2rem;

    .title{
      font-size: $fontSizeXLarge;
    }
  }

  .branding-background {
    background-color: $grey1;
  }

  .padding-branding-row {
    margin-top: 0.5rem;
  }

  .padding-branding-label-row {
    margin-top: 0.6rem;
  }

  .padding-branding-left {
    padding-left: 0rem;
  }

  .padding-branding-top {
    padding-top: 0.4rem;
  }

  .is-abp-eligible-wrapper{
    display: inline-block;
    vertical-align: middle;
    padding-left: 1rem;
  }

  .is-abp-eligible{
    color: rgba(0, 0, 0, 0.55);
    font-size: 0.875rem;
  }
  input[disabled] + label {
    cursor: default;
  }
}

.partner-list-section {
  margin-top: 1rem;

  .row{
    padding: 0.5rem 0;
  }

  .partner-list-table{
    border : 0rem;
    td{
      border-bottom : 1px solid get-color(gray, 4);
      padding: 1rem;
    }
    tr{
      background: get-color(gray,1);

    }
    .partner-name{
      font-size: $fontSizeMedium;
      a{
        text-decoration: none;
      }
    }
  }
  .partner-details{
    color: $grey6;
    font-size: $fontSizeSmall;
  }

  .partner-search-bar{
    height: 3.2rem;
  }
  .partner-pipe{
    color: get-color(gray,4);
  }
}


.add-partner-header{
    margin-bottom: 1rem;

    h1{
      color: get-color(orange,8);
      font-weight: $fontWeightBold;
    }
}

.reveal-modal{
    a {
      text-decoration: none;
    }
  width: 40%;
  border-top:2px solid #fe7800;
}

.switch input:checked + label {
  background: $orange5;
}

.switch {
  margin-bottom:0rem;
}

.error-message{
      position: relative;
      min-height: 2.5rem;
      padding: 1rem;
      -webkit-border-radius: 0.3125rem;
      -moz-border-radius: 0.3125rem;
      border-radius: 0.3125rem;
      margin-bottom: 1rem;
      font-style: normal;
      font-size: $fontSizeXSmall;
      line-height: $lineHeightXSmall;
      opacity: 1;
      color: $colorAttentionTwo;
      background: $colorBackgroundThirteen;
  }

.partner-link-cta {
  color: $pink5;
  font-size: $fontSizeXSmall;
  text-align: right;
}
.partner-link-cta:hover {
  color: $grey7;
}


.title-link {
  margin-top: 13px;
}

.partner-pagination {
  border-bottom: 1px solid get-color(gray,4);
  font-size: 1rem;
  color: $grey7;
  a:hover {
    color: $grey7;
  }
  .inactive, .inactive:hover {
    color: $grey4;
    cursor: default;
  }
  .division {
    color: $grey4;
    margin: 0 .25em;
  }
}

.partner-row {
  border-bottom: 1px solid $grey3;
}

.partner-row:hover {
  background-color: $grey1;
  cursor: pointer;
}

.mb10 {
  margin-bottom: 10px;
}

.abp-caption{
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.5rem;
  margin-bottom: 1rem;
}

select#channel{
  padding-right: 0.2rem;
}

.campaign-help-pannel-header{
  font-weight: $fontWeightBold;
  padding-top: 0.8rem;
}

.campaign-help-pannel-text{

}

.campaign-help-pannel-back-link{
  color: $pink5;
  font-size: $fontSizeXSmall;
}
.channel-help-link{
  a{
    text-decoration: underline;
  }
  a:hover {
    color: $grey7;
  }
}
