/*  ==================================================================
    Define the baselines for all the common elements
    ==================================================================  */


body {

  .content-area{
    margin-top: 10px;
  }



  .hide{
    display: none !important;
  }

  .stretch-full-width{
    width: 100% !important;
  }

  .strong{
    font-weight: bold;
  }

  .content-centered{
    margin: auto;
  }

  //input {
  //  display: block;
  //  position: absolute;
  //  box-sizing: border-box;
  //  width: 100%;
  //  border: 0;
  //  top: $inputTop;
  //  background: none;
  //  z-index: 1;
  //  padding: $defaultPadding;
  //  font-size: $fontSizeForm;
  //  letter-spacing: $letterSpacing;
  //}
  //
  //$animations: labelIn, labelOut;
  //$animationTime: .35s;
  //$ease: cubic-bezier(0.770, 0.000, 0.175, 1.000); /* easeInOutQuart */
  //
  //label {
  //  display: block;
  //  position: absolute;
  //  margin-top: 2px;
  //  padding: $defaultPadding;
  //  letter-spacing: $letterSpacing;
  //  color: $hintTextColor;
  //  font-size: $fontSizeForm;
  //
  //  animation-name: nth($animations, 1);
  //  animation-duration: $animationTime;
  //  animation-direction: reverse;
  //  animation-fill-mode: forwards;
  //  animation-timing-function: $ease;
  //}
  //
  //// Change label position on focus & valid
  //input:focus + label, input:valid + label {
  //  animation-name: nth($animations, 2);
  //  animation-duration: $animationTime;
  //  animation-direction: normal;
  //  animation-fill-mode: forwards;
  //  animation-timing-function: $ease;
  //}
  //
  //// Create separate animation keyframes for in and out animations to avoid conflicts
  //@each $animationName in $animations {
  //  @keyframes #{$animationName} {
  //    0% {
  //      left: $borderSize;
  //      opacity: 1;
  //      top: $inputTop;
  //      font-size: $fontSizeForm;
  //      font-weight: 300;
  //    }
  //    50% {
  //      font-size: $fontSizeForm;
  //      left: 1em;
  //      opacity: 0;
  //      top: $inputTop;
  //      font-weight: 300;
  //    }
  //    50.01% {
  //      font-size: $fontSizeLabel;
  //      left: 1em;
  //      opacity: 0;
  //      top: 0.2em;
  //      color: $labelTextColor;
  //      font-weight: 400;
  //    }
  //    100% {
  //      font-size: $fontSizeLabel;
  //      opacity: 1;
  //      left: $borderSize;
  //      top: 0.2em;
  //      color: $labelTextColor;
  //      font-weight: 400;
  //    }
  //  }
  //}
  //
  //
  //
  //// disable focus outline
  //*:focus {
  //  outline: 0;
  //}

  //.input {
  //  position: relative;
  //  z-index: 1;
  //  display: inline-block;
  //  margin: 1em;
  //  max-width: 350px;
  //  width: calc(100% - 2em);
  //  vertical-align: top;
  //}
  //
  //.input__field {
  //  position: relative;
  //  display: block;
  //  float: right;
  //  padding: 0.8em;
  //  width: 60%;
  //  border: none;
  //  border-radius: 0;
  //  background: #f0f0f0;
  //  color: #aaa;
  //  font-weight: bold;
  //  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //  -webkit-appearance: none; /* for box shadows to show on iOS */
  //}
  //
  //.input__field:focus {
  //  outline: none;
  //}
  //
  //.input__label {
  //  display: inline-block;
  //  float: right;
  //  padding: 0 1em;
  //  width: 40%;
  //  color: #6a7989;
  //  font-weight: bold;
  //  font-size: 70.25%;
  //  -webkit-font-smoothing: antialiased;
  //  -moz-osx-font-smoothing: grayscale;
  //  -webkit-touch-callout: none;
  //  -webkit-user-select: none;
  //  -khtml-user-select: none;
  //  -moz-user-select: none;
  //  -ms-user-select: none;
  //  user-select: none;
  //}
  //
  //.input__label-content {
  //  position: relative;
  //  display: block;
  //  padding: 1.6em 0;
  //  width: 100%;
  //}
  //
  //.graphic {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  fill: none;
  //}
  //
  //.icon {
  //  color: #ddd;
  //  font-size: 150%;
  //}
  //
  ///* Juro */
  //.input--juro {
  //  overflow: hidden;
  //}
  //
  //.input__field--juro {
  //  position: absolute;
  //  z-index: 100;
  //  padding: 2.15em 0.75em 0;
  //  width: 100%;
  //  background: transparent;
  //  color: #1784cd;
  //  font-size: 0.85em;
  //}
  //
  //.input__label--juro {
  //  padding: 0;
  //  width: 100%;
  //  height: 100%;
  //  background: #fff;
  //  text-align: left;
  //}
  //
  //.input__label-content--juro {
  //  padding: 2em 1em;
  //  -webkit-transform-origin: 0% 50%;
  //  transform-origin: 0% 50%;
  //  -webkit-transition: -webkit-transform 0.3s, color 0.3s;
  //  transition: transform 0.3s, color 0.3s;
  //
  //  text-rendering: geometricPrecision;
  //}
  //
  //.input__label--juro::before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  border: 0px solid transparent;
  //  -webkit-transition: border-width 0.3s, border-color 0.3s;
  //  transition: border-width 0.3s, border-color 0.3s;
  //}
  //
  //.input__field--juro:focus + .input__label--juro::before,
  //.input--filled .input__label--juro::before {
  //  border-width: 8px;
  //  border-color: #1784cd;
  //  border-top-width: 2em;
  //}
  //
  //.input__field--juro:focus + .input__label--juro .input__label-content--juro,
  //.input--filled .input__label--juro .input__label-content--juro {
  //  color: #fff;
  //  -webkit-transform: translate3d(0, -1.5em, 0) scale3d(0.75, 0.75, 1);
  //  transform: translate3d(0, -1.5em, 0) scale3d(0.75, 0.75, 1) translateZ(1px);
  //}

//
//<span class="input input--juro">
//  <input class="input__field input__field--juro" type="text" id="input-28">
//  <label class="input__label input__label--juro" for="input-28">
//    <span class="input__label-content input__label-content--juro">First Name</span>
//  </label>
//</span>

}