$notification-types    : (
        (failure icon_exclamation_white get-color(red, 4))
        (reminder icon_reminder_white get-color(orange, 7))
        (success icon_success_white get-color(green, 9))
        (warning icon_exclamation_white get-color(orange, 7))
        (info icon_info_white get-color(teal, 3))
) !default;

.notification-bar {
    display            : table;
    color              : get-color(gray, 1);
    padding            : 0;

    .notification-icon,
    .notification-title,
    .notification-close {
      display          : table-cell;
      vertical-align   : middle;
      height           : 3rem;
      padding-top      : rem-calc(10);
      padding-bottom   : rem-calc(10);
      @media #{$medium-up} {
        height         : 3.5rem;
      }
    }

    .notification-icon {
      width            : 3rem;
      text-align       : center;
      border-right     : 1px solid get-color(gray, 1);
      background       : asset-url('#{$theme_image_path_current}icon_exclamation_white.svg') no-repeat center center;
      background-size  : rem-calc(20) rem-calc(20);

      @media #{$medium-up} {
        width          : 4rem;
        background-size: rem-calc(26) rem-calc(26);
      }
    }

    .notification-title {
      padding-left     : rem-calc(10);
      .notification-heading{
        // @include font-size($small-font-sizes);
        line-height    : 1.2rem !important;
        font-weight    : 600;
      }
      .notification-subtext{
        // @include font-size($small-font-sizes);
        line-height    : 1.2rem !important;
      }
      @media #{$medium-up} {
        padding-left   : 1.875rem;
      }
    }

    .notification-close {
      width            : 3rem;
      position         : absolute;
      right            : 1rem;
      text-align       : center;
      line-height      : rem-calc(15);
      cursor           : pointer;
      background       : asset-url('#{$theme_image_path_current}icon_close_white.svg') no-repeat center center;
      background-size  : rem-calc(15) rem-calc(15);
    }
}

@each $notification-type in $notification-types {
  $type                : nth($notification-type, 1);
  $icon                : nth($notification-type, 2);
  $color               : nth($notification-type, 3);

  .notification-#{$type} {
    background         : $color !important;
    .notification-icon {
      background-image : asset-url('#{$theme_image_path_current}#{$icon}.svg') !important;
    }
  }
}
