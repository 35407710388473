body {
  font-family: $fontFamily;
  @include font-size($p-font-sizes);
  @include make-font-weight(4);
  background-color: get-color(gray, 2);
  color: get-color(gray, 8);

  p, h1, h2, h3, h4, h5, h6, strong, small {
    font-family: $fontFamily;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: get-color(gray, 8);
  }



  h1 {
    @include font-size($h1-font-sizes);
    @include make-font-weight(3);
  }

  h2 {
    @include font-size($h2-font-sizes);
    @include make-font-weight(3);
  }

  h3 {
    @include font-size($h3-font-sizes);
    @include make-font-weight(3);
  }

  h4 {
    @include font-size($h4-font-sizes);
    @include make-font-weight(3);
  }

  h1.large {
    @include font-size($h1-large-font-sizes);
    @include make-font-weight(3);
  }


  p {
    padding: 0;
    @include font-size($p-font-sizes);
    @include make-font-weight(4);
  }

  p.caption {
    @include font-size($caption-font-sizes);
    @include make-font-weight(4);
  }

  p.disclaimer {
    @include font-size($disclaimer-font-sizes);
    @include make-font-weight(4);
    color: get-color(gray, 7);
  }





}