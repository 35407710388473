/*=========================================
=    activity_feed SCSS         =
=========================================*/
ul.activity-stream {
  width:100%;
  padding: rem-calc(35px) rem-calc(25px) rem-calc(25px) rem-calc(15px);
  float:left;
  list-style: none;
  margin-left:0;
  li {
    margin-bottom:2rem;
    float:left;
    @media #{$medium-up} {
      margin-bottom: 0.5rem;
      min-height: 4.5rem;
      float: left;
      width:100%;
    }
  }
  .date-cluster {
    float:left;
    width:100%;
    @media #{$medium-up} {
      width:25%;
    }
    @media #{$large-up} {
      width:15%;
    }
    .activity-feed-date {
      width: 30%;
      margin: 0 10%;
      text-align: center;
      float: left;
      margin-top: -0.5rem;
      .activity-date, .activity-month {
        display: block;
        color: get-color(gray, 6);
      }
      .activity-date {
        font-weight: $fontWeightLight;
        line-height: 2rem;
        font-size: $fontSizeXLarge;
      }
      .activity-month {
        font-size: $fontSizeSmall;
      }
    }
    i {
      margin-top:.9rem;
      background-image: asset-url("#{$theme_image_path}icon-green-check-circle.svg");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: rem-calc(45px) rem-calc(45px);
      display: block;
      height: rem-calc(45px);
      float:left;
      width: 30%;
      margin: 0 10%;
      @media #{$medium-up} {
        background-size: rem-calc(26px) rem-calc(26px);
        height: rem-calc(26px);
        float:left;
        &:after {
          position: relative;
          background: get-color(gray, 4);
          content: "";
          top: rem-calc(38px);
          left: rem-calc(9px);
          height: rem-calc(35px);
          width: rem-calc(7px);
          float: left;
          border-radius: rem-calc(3px);
        }
      }
    }
  }
  p {
    color:get-color(gray, 6);
    float:left;
    width:100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid get-color(gray, 4);
    @media #{$medium-up} {
      width: 75%;
      margin-top: .4rem;
      padding-bottom: 1.3rem;
    }
    @media #{$large-up} {
      width: 85%;
    }
  }
}
