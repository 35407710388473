.custom-top-bubble{
  @include psp-create-bubble($nob-position: top, $bg: get-color(teal, 3));
}

.custom-bottom-bubble{
  @include psp-create-bubble($nob-position: bottom, $bg: get-color(teal, 3));
}

.custom-left-bubble{
  @include psp-create-bubble($nob-position: left, $bg: get-color(teal, 3));
}

.custom-right-bubble{
  @include psp-create-bubble($nob-position: right, $bg: get-color(teal,3));
}
