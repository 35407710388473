@mixin foundation-menu-icon {
  $-zf-menu-icon-imported: true !global;

  .menu-icon {
    @include hamburger($color: $titlebar-icon-color, $color-hover: $titlebar-icon-color-hover);
  }

  .menu-icon.dark {
    @include hamburger;
  }
}
