.sign-in-container {
  margin-top: 2.2rem;
  h1{
    padding-bottom: 1.5rem;
  }
  #email, #password {
    background-color: white;

    &:-webkit-autofill {
      background-color: white !important;
    }
  }

  input[name="password"] { 
    background-image: asset-url("#{$theme_image_path}icon_lock_outline_gray.svg"); 
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    background-position: 95% center;
  }

  .password-validation-container {
    display: none;
  }

  .error-message{
      position: relative;
      min-height: 2.5rem;
      padding: 1rem;
      -webkit-border-radius: 0.3125rem;
      -moz-border-radius: 0.3125rem;
      border-radius: 0.3125rem;
      margin-bottom: 1rem;
      font-style: normal;
      font-size: $fontSizeXSmall;
      line-height: $lineHeightXSmall;
      opacity: 1;
      color: $colorAttentionTwo;
      background: $colorBackgroundThirteen;
  }
}

@media #{$medium-up} {
  .sign-in-container {
    margin-bottom: 100px;
  }
}
