body, p {
  font-size: $fontSizeMedium;
  color: $colorTextOne;

}

body, h1, h2, h3 {
  font-family: $fontFamily;
}

body {
  background-color: $colorBackgroundOne;
  font-weight: $fontWeightNormal;
  font-style: normal;
}

h1, h2, h3 {
  font-weight: $fontWeightLight;
  &.light-header {
    font-weight: $fontWeightLight;
  }
}

.light-subheader {
  font-weight: $fontWeightLight;
  font-size: $fontSizeMedium;
  line-height: $lineHeightMedium;
  letter-spacing: $spacingTighter;
}

h1 {
  color: $colorTextOne;
  font-size: $fontSizeLarge;
  line-height: $lineHeightLarge;
}

h2 {
  font-size: $fontSizeMedium;
}

h3 {
  color: $colorTextOne;
  font-size: $fontSizeLarge;
  line-height: $lineHeightLarge;
  text-align: center;
}

p {
  strong {
    font-weight: $fontWeightBold;
  }
}

a {
  color: $grey7;
  font-weight: $fontWeightNormal;
  text-decoration: underline;

  &.check-rate {
    text-decoration: none;
  }
  &:hover{
    color:$pink5;
  }
}

select {
  margin-bottom: 1rem;

}

footer{
  a{
    color: $grey6;
    &:hover{
      color:$pink5;
    }
  }
}

/*  ==================================================
    Forms, Radios and Checkboxes
    ==================================================  */

form {
  select,
  input:not([type='radio']):not([type='checkbox'])
   {
    background-color: $colorBackgroundOne;
    border: 1px $grey4 solid;
    outline: none;
    box-shadow: none;
    height: 3.5rem;
    padding: 1rem;
    font-size: $fontSizeMedium;
    line-height: $lineHeightMedium;

    &:active,
    &:hover,
    &:focus {
      background-color: $colorBackgroundOne;
      border: 2px $grey7 solid;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,.5);;
      transition: none;
      height: 3.625rem;
      margin-bottom: 0.875rem;
      margin-left: -1px;
      padding-top: 1rem; // Fixes firefox render bug
    }

    // Hides IE 11's "x" icon when user types in a value in field
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }

  label {
    font-size: $fontSizeXSmall;
    line-height: $lineHeightXSmall;

    a {
      font-weight: $fontWeightNormal;
      text-decoration: underline;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $colorTextOne;
    background-image: asset-url("#{$theme_image_path}icon_down_arrow_black.svg");
    background-size: .8rem auto;
    background-position: 93% center;
    padding-top: 0.95rem;
    padding-bottom: 0.95rem;
    &:active,
    &:hover,
    &:focus {
      padding-top: 0.95rem;
    }
  }

  select::-ms-expand {
    display: none; // hides select drop down arrow in IE10+
  }

  // Hides number spinner for Chrome, Safari and Firefox
  // We are using type 'number' so Angular validation
  // will work on min and max lengths
  input[type=number],
  input[type=date],
  input[type=month] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-calendar-picker-indicator {
      -webkit-appearance: none;
      margin: 0;
      display: none;
    }

  }

  input[type=date],
  input[type=month] {
    &::before {
      width: 100%;
      content: attr(placeholder);
      color: #aaa; // browser's default placeholder color
    }
    &.date-value::before {
      content: initial;
    }
    &.date-focus:focus::before {
      width: 0;
      content: '';
    }
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
   // -moz-appearance: none;  // For now we want the Firefox default appearance
    appearance: none;
    background-color: $colorBackgroundOne;
    border: 1px solid $colorBorderSeven;
    margin: 5px 0 2.5rem;
    height: 1.5rem;
    width: 1.5rem;
    outline:0;

    &:checked {
      background: $colorBackgroundOne center asset-url('#{$theme_image_path}icon_check_blue.svg') no-repeat;
      background-size: 15px 17px;
    }

    + label {
      &:focus, &:active, &:visited, &:hover {
        outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

  .checkbox-wrapper{
    margin-bottom: 0.2rem;
    input[type='checkbox'] {
      display: inline-block;
      margin: 0;
      width: 2rem;
      height: 2rem;
      vertical-align: middle;
    }
    label{
      display: inline-block;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.55);
      font-size: 1rem;
    }
  }


  input[type="radio"]{
    display:none;
  }

  input[type="radio"] + label {
    background-color: $colorBackgroundOne;
    box-shadow: 0px 0px 0px 1px $colorBorderTwo;
    border: 0.1875rem solid $colorBorderSix;
    height: 1.25rem;
    width: 1.25rem;
    display: inline-block;
    margin: 0.5rem 6.5rem 1.5rem 0.25rem;
    vertical-align: bottom;
    border-radius: 1.5rem;

    outline: none;

    > p {
      color: $colorTextOne;
      font-weight: $fontWeightBold;
      font-size: $fontSizeSmall;
      line-height: $lineHeightSmall;
      padding-left: 2rem;
    }
  }


  input[type="radio"]:checked + label {
    background-color: $colorBackgroundTen;
    color: $colorTextOne;
  }

  input[disabled],
  select[disabled] {
    background-color: $colorBackgroundFourteen !important; // overrides Foundation styles
    border: 0;
    &:active,
    &:hover,
    &:focus,
    &:visited {
      background-color: $colorBackgroundFourteen !important; // overrides Foundation styles
      border: 0;
      box-shadow: none;
      outline: none;
    }
  }

  .success {
    input:not([type='radio']):not([type='checkbox']),
    select {
      background-image: asset-url("#{$theme_image_path}icon_success_check.svg");
      background-repeat: no-repeat;
      background-size: .8em auto;
      background-position: 95% center;
      padding-right:3.5rem;
    }

    input[type='checkbox'] {
      border-color: $colorAttentionOne;
    }
  }

  .error {

    label,
    li {
      color: $colorAttentionTwo;
    }

    input,
    textarea,
    select {
      margin-bottom: 1rem;
    }

    input:not([type='radio']):not([type='checkbox']),
    select {
      border-color: $colorAttentionTwo;
      background-image: asset-url("#{$theme_image_path}icon_error_bang.svg");
      background-repeat: no-repeat;
      background-size: 1.5rem 1.5rem;
      background-position: 95% center;

    }




      input[type='checkbox'] {
      background-image: none;
      border-color: $colorAttentionTwo;

      &:checked {
        background: center asset-url('#{$theme_image_path}icon_check_blue.svg') no-repeat;
        background-size:  0.9375rem 1.063rem;
      }
    }

    .error-message {
      font-size: $fontSizeXSmall;
      display: block;
      margin-top: 0.5rem;
    }

  }

  .alternate-error-message {
    display: none;
    text-align: center;
    border-top: 1px solid $colorAttentionTwo;
    border-bottom: 1px solid $colorAttentionTwo;
    margin-bottom: 6rem;

    p {
      font-size: $fontSizeXSmall;
      margin: 0;
    }
  }
}

/*  ==================================================
    Buttons and Links
    ==================================================  */

.check-rate-button {
  background: $colorBackgroundOne;
  color: $colorTextFour;
  font-weight: $fontWeightBold;
  margin: 0.9em 0 2em;
  padding: 0;
  border: solid 0.1875rem $colorBorderFour;
  width: rem-calc(240px);
  height: 3.5rem;
  line-height: $lineHeightXLarge;
  border-radius: 1.875rem;
  max-width: 18rem;
  text-align: center;

  a {
    margin-left: -10%;   // Code Review - is this needed?
  }

  .check-rate-icon {
    width: rem-calc(12px);
    height: rem-calc(18px);
    margin-left: rem-calc(6px);
    position: relative;
    bottom: rem-calc(2px);
  }

}

.continue,
.continue:hover,
.continue:active,
.continue:focus {
  color: $colorTextFour;
  font-weight: $fontWeightBold;
  padding: 1.2rem;
  margin: 0;
  background: right center asset-url('#{$theme_image_path}arrow_right_blue.svg') no-repeat;
  background-size: .8rem auto;
}

.funnel-CTA {
  .back {
    border-right: 1px white solid;
    background: $colorTextFour center asset-url('#{$theme_image_path}arrow_tailed_left_white.png') no-repeat;
    background-size: 2em;
    color: transparent;

    &:focus, &:active, &:visited, &:hover {
      color: transparent;
    }
  }

  .forward, .forward[disabled] {
    background: $colorTextFour 90% asset-url('#{$theme_image_path}arrow_tailed_right_white.png') no-repeat;
    background-size: 2em;
  }

  .forward[disabled] {
    opacity: 0.6;
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }

  .button,
  .button:active,
  .button:focus {
    background: $colorBackgroundTen;
    border-bottom: 4px solid $red5;
    text-shadow: -1px -1px $red5;
    box-shadow: 0px 0.5px 0.875px 0px rgba(0,0,0,0.5);
    @include psp-border-radius(4px);

    color: $colorBaseFive;
    font-weight: $fontWeightBold;
    text-decoration: none;
    max-width: 18rem;
    width: 7.5rem;

    &:hover {
      background: $pink6;
      border-bottom: 4px solid $pink7;
      text-shadow: -1px -1px $pink7;
    }

    &.basic {
      background: transparent;
      border: 1px solid $colorBorderSeven;
      color: $colorTextOne;
    }

    &.loading {
      color: $colorTextOne;
      border: 1px solid $colorBorderOne;
      background: none;

      .spinner {
        height: 28px;
        width: 28px;
        position: absolute;
        display: inline-block;
        margin-left: 1rem;
        top: 12px;
        border: 4px solid $colorBorderOne;
        border-right-color: transparent;
        border-radius: 50%;

        -webkit-animation: rotate 1.5s infinite linear;
        -moz-animation: rotate 1.5s infinite linear;
        -o-animation: rotate 1.5s infinite linear;
        animation: rotate 1.5s infinite linear;
      }
    }
  }
}

.informational,
.informational:active,
.informational:focus {
  background-color: $colorBackgroundThree;
  border: 4px solid transparent;
  color: $colorTextFour;
  font-weight: $fontWeightBold;
}

.informational:hover {
  background: transparent;
  border: 4px solid $colorBorderSix;
}

.contact-button {
  padding-bottom: 1rem;
  border-bottom: 1px solid $colorBorderSeven;

  .call-us-message {
    font-size: $fontSizeXSmall;
    color: $colorTextOne;
    margin-bottom: 1rem;
  }

  .contact-button {
    display: inline-block;
    border: 1px solid $colorBorderFour;
    font-weight: $fontWeightNormal;
    font-size: $fontSizeXSmall;
    color: $colorTextThree;
    min-width: 261px;
    border-radius: 5px;

    > span {
      color: $colorTextFour;
    }
  }

  &.call .contact-button {
    padding: 1rem 1.5rem 1rem 3.4rem;
    background: asset-url('#{$theme_image_path}icon_telephone_outline_gray.svg') no-repeat 10%;
    background-size: 1.3rem;
    background-position-y: 47%;
  }

  &.email .contact-button {
    padding: 1rem 1.25rem 1rem 4.5rem;
    background: asset-url('#{$theme_image_path}icon_email.svg') no-repeat 20%;
    background-size: 1rem;
    background-position-y: 47%;
  }
}



/*Utility Classes*/

.container {
  padding: 10px;
}

.invisible {
  visibility: hidden;
}

.proper {
  text-transform: capitalize;
}

.dollar-amount {
  position: relative;

  input:hover+.amount-span,
  input:active+.amount-span,
  input:focus+.amount-span {
    line-height: 1.4rem;
    //top: 1.1rem;
    left: 0.05rem;
  }

  .amount-span {
    top: 1.063rem;
    left: 0.0625rem;
    line-height: 1.375rem;
    color: $colorTextFive;
    position: absolute;
    background-color: $colorBackgroundFourteen;
    display: inline-block;
    font-weight: $fontWeightNormal;
    margin-top: -1rem;
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    width: auto;
  }


  input:not([type='radio']):not([type='checkbox']) {
    padding-left: 3rem;
  }


}


.dollar-amount
.dollar-amount input[type=number]::-webkit-outer-spin-button,
.dollar-amount input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dollar-amount input[type=number] {
  -moz-appearance: textfield;
}

.help-text {
  display: none;
  font-size: $fontSizeXSmall;
  line-height: $lineHeightMedium;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.full-width {
  padding: 0;
}

.left-column {
  padding-right: 10px;
}

.right-column {
  padding-left: 10px;
}

/*Modal Styles*/

.modal-page {
  display: none;
  width: 100%;
  height: 100%;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.7);
  background-image: asset-url('#{$theme_borrower_image_path}piggy_interstitial.gif');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 40%;
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0px;
}

/*Google Places styles*/

.pac-icon-marker {
  display: none;
}

.pac-container {
  box-shadow: none;
  border: 1px solid $colorBorderTwo;
}

.pac-item, .pac-item-query {
  font-family: $fontFamily;
  font-size: $fontSizeMedium;
}

.pac-item {
  color: $black;
  padding: 1rem 0.5rem;
  background-color: $colorBackgroundThree;
  border: 4px solid $colorBorderSix;
  border-bottom: 2px solid $colorBorderSix;
}

.help-panel{
  padding: 0.1rem 0rem 0rem 0rem;

  table {
    height: 150px;

    tr th{
      border: 1px solid #dddddd;
    }
    tr td {
     background: $colorBaseFive;
    }
  }


  ul{
    list-style-type: none;
    margin: 0rem 0rem 0rem -0.5rem;
    li{
      padding: 1.0rem 1.25rem 1rem 1.5rem;
    }
  }
  .call-us{
    padding: 1.0rem 1.25rem 1rem 3.2rem;
  }

}

@media #{$small-up}{
  .small-help-panel{
    display: none;
  }
}


@media #{$small-only} {
  .help-panel{
    display: none;
  }

  .small-help-panel{
    display: inline;
  }
}

.icon-lock-gray{
  background-image: asset-url("#{$theme_image_path}icon_lock_outline_gray.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem auto;
  background-position: 95% center;
}
.icon-clock-gray{
  background-image: asset-url("#{$theme_image_path}icon_clock_gray.svg");
  background-repeat: no-repeat;
  background-size: 1.1rem;
}


.icon-search-gray{
  background-image: asset-url("#{$theme_image_path}icon_search_gray.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;

    padding: 1.54rem 2px;
    text-indent: 20px;

    -webkit-transition: all 0.2s;
    -moz-transition: all 2s;
    transition: all 0.2s;
    width: 2.4rem;
}

.icon-question-mark-blue{
  background-image: asset-url("#{$theme_image_path}icon_question_mark_blue.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  background-position: 2% center;
}

/*  ==================================================
    For Firefox version 30 and above
    ==================================================  */

@-moz-document url-prefix() {

  form {
    select,
    .error select,
    .success select {
      width: 100%;

      /* NOTE: these three styles together are the only way
      to prevent Firefox from displaying the default select
      drop down look and feel */
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
    }

  }

}

/*  ==================================================
    Validation
    ==================================================  */


input[type="number"]:focus{
    border:2px solid $colorBaseOne;
}

.bubble {
    position: relative;
    min-height: 2.5rem;
    padding: 1rem;
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    border-radius: 0.3125rem;
    background: $colorBackgroundFourteen;
    margin-bottom: 1rem;
    font-style: normal;
    font-size: $fontSizeXSmall;
    line-height: 1rem;
    opacity: 1;


    &.ng-hide {
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 0;
      min-height: 0;
      border-radius: 0;
    }

    &.ng-hide-add-active {
      display: block!important;
      -webkit-transition: all linear 0.04s;
      transition: all linear 0.04s;
    }

    &.ng-hide-remove-active {
      display: block!important;
      -webkit-transition: all linear 0.04s;
      transition: all linear 0.04s;
      -webkit-transition-delay: 0.04s;
      transition-delay: 0.04s;
    }



    p {
      font-style: normal;
      font-size: $fontSizeXSmall;
      line-height: $lineHeightXSmall;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &.error{
        color: $colorAttentionTwo;
        background: $colorBackgroundThirteen;
        p {
          color: $colorAttentionTwo;
        }
    }


}

.bubble:after {
    content: "";
    position: absolute;
    top: -0.625rem;
    left: 1.875rem;
    border-style: solid;
    border-width: 0 8px 11px;
    width: 0;
}

.bubble.error:after{
     border-color: $colorBackgroundThirteen transparent;

}

.bubble.field-hint:after{
     border-color: $colorBackgroundFourteen transparent;
}
.bubble.user-message{
  background: $colorBackgroundNineteen;
}
.bubble.user-message:after{
  border-color: $colorBackgroundNineteen transparent;
}

/* On Hover Tool tip */
[data-tip] {
//    position:relative;

}






.angular-float-labels-wrapper {
  position: relative;
}
.angular-float-labels-label {
  @include psp-transition($property:all, $speed:300ms, $ease:ease-out, $delay:0s);
  position: absolute;
  left: 1.1rem;
  top: 0.45rem;
  opacity: 0;
  font-weight: $fontWeightNormal;
  color: $colorTextThree ;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
  pointer-events: none;
}

.dollar-amount {
  .angular-float-labels-label {
    left: 3.3rem;
    }
}
.angular-float-labels-element {

  &:not([type='radio']):not([type='checkbox']) {
    padding: 1rem 1rem 1rem 1rem;

  }


  &.ng-dirty {
    color: $colorTextOne;
  }

}

.angular-float-labels-label.toggled {
  display: block;
  opacity: 1;
}

.angular-float-labels-label.toggled + .angular-float-labels-element,
.selectize-control.multi .angular-float-labels-label.toggled + .angular-float-labels-element.has-items {
  padding-bottom: 0.3125rem;
  padding-right: 2.80 rem;
}

.angular-float-labels-label.focused,
.angular-float-labels-label:hover
 {
  color: $grey6;
}

// used to override backgrounds in form elements while user is typing, removed by javascript when validation is engaged
.no-background{
  background-image: none !important;
}

.loader {
  margin: 5rem auto;
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-animation 1.4s infinite linear;
  animation: loader-animation 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  &:before {
    width: 50%;
    height: 50%;
    background: $orange5;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &.loader-alternate {  // loader-alternate uses white background
    &:after {
      background: $white;
    }
  }
  &:after {
    background: $white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

}
@-webkit-keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal:focus {
  outline: none;
}
