.title-bar{
  height:5rem;
  padding: 0.5rem 0;
  background-color: get-color(gray,1);
  border-bottom:1px solid get-color(gray,5);
  ul{
    background-color: get-color(gray,1);
  }
  a{
    color: get-color(gray,8);
    font-size: 1.2rem;
    font-weight: 300;
    text-decoration: none;
    &:hover{
      color: get-color(magenta,7);
    }
  }
  .menu-text{
    padding: 0.7rem 0;
  }
  //Leftside-Icon
  .title-logo{
    display: block;
    width: 13.75rem;
    height: 2.5rem;
    margin-left: 2.5rem;
    background-image: asset-url("#{$theme_image_path_current}logo_prosper_color_gray.svg");
    background-size: 13.75rem 2.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  //rightside-menu
  .rightside-menu{
    margin-right: 2.5rem;
    margin-top: 0.4rem;
    .logged-in{
      button{
        min-width: 15rem;
        width: auto;
        height: 2.8125rem;
        padding: 0 3rem;
        .arrow-bottom{
          @include chevron($direction:"bottom", $color: get-color(magenta,7));
          display: inline-block;
          margin:  0.3rem 0 0 1rem;
          float: right;
        }
        &:hover, &:focus{
          .arrow-bottom{
            border-color: get-color(gray,1);
          }
        }
      }
      .menu{
        &>li{
          height: 3.125rem;
          min-width: 15rem;
        }
        .signout{
          border-top: 1px solid get-color(gray,5);
        }
      }
    }
    .logged-out{
      .menu{
        &>li{
          height: 3.75rem;
        }
      }
    }
  }

}

//---Media Query--------
//-----------------------

@media (min-width: 481px) and (max-width: 1023px) {
  .title-bar{
    height:3.75rem;
    padding: 0.2rem;
    .menu-text{
      padding: 0.5rem 0;
    }
    .rightside-menu{
      margin-top: 0.1rem;
    }
  }
}

@media (min-width: 481px) and (max-width: 640px) {
  .title-bar{
    .title-logo{
      margin-left: 1.25rem;
    }
    .rightside-menu{
      margin-right: 1.25rem;
    }
  }
}
