
/*  ==================================================================
    Responsive typography levels ranging from -2 to +5, contains both font-size and line-height
    -2 being smallest
    +5 being largest

    Inside each level, there are three sub-divisions based on device ranges
    small - Mobile phones
    medium - Tablets
    large - Desktops and above
    ==================================================================  */

$responsive-type-levels: (
        -2: (
                small: (
                        font-size: 0.75rem, //12px
                        line-height: 1.125rem //18px
                ),
                medium: (
                        font-size: 0.75rem, //12px
                        line-height: 1.125rem //18px
                ),
                large: (
                        font-size: 0.75rem, //12px
                        line-height: 1.125rem //18px
                )
        ),
        -1: (
                small: (
                        font-size: 0.875rem, //14px
                        line-height: 1.313rem //21px
                ),
                medium: (
                        font-size: 0.875rem, //14px
                        line-height: 1.313rem //21px
                ),
                large: (
                        font-size: 0.875rem, //14px
                        line-height: 1.313rem //21px
                )
        ),
        0: (
                small: (
                        font-size: 1rem, //16px
                        line-height: 1.5rem //24px
                ),
                medium: (
                        font-size: 1rem, //16px
                        line-height: 1.5rem //24px
                ),
                large: (
                        font-size: 1rem, //16px
                        line-height: 1.5rem //24px
                )
        ),
        1: (
                small: (
                        font-size: 1.25rem, //20px
                        line-height: 1.5rem //24px
                ),
                medium: (
                        font-size: 1.25rem, //20px
                        line-height: 1.5rem //24px
                ),
                large: (
                        font-size: 1.25rem, //20px
                        line-height: 1.5rem //24px
                )
        ),
        2: (
                small: (
                        font-size: 1.5rem, //24px
                        line-height: 1.875rem //30px
                ),
                medium: (
                        font-size: 1.5rem, //24px
                        line-height: 1.875rem //30px
                ),
                large: (
                        font-size: 1.5rem, //24px
                        line-height: 1.875rem //30px
                )
        ),
        3: (
                small: (
                        font-size: 2rem, //32px
                        line-height: 2.5rem //40px
                ),
                medium: (
                        font-size: 2rem, //32px
                        line-height: 2.5rem //40px
                ),
                large: (
                        font-size: 2rem, //32px
                        line-height: 2.5rem //40px
                )
        ),
        4: (
                small: (
                        font-size: 2.25rem, //36px
                        line-height: 2.813rem //45px
                ),
                medium: (
                        font-size: 3rem, //48px
                        line-height: 3.75rem //60px
                ),
                large: (
                        font-size: 3rem, //48px
                        line-height: 3.75rem //60px
                )
        ),
        5: (
                small: (
                        font-size: 4.5rem, //72px
                        line-height: 5.635rem //90px
                ),
                medium: (
                        font-size: 4.5rem, //72px
                        line-height: 5.635rem //90px
                ),
                large: (
                        font-size: 4.5rem, //72px
                        line-height: 5.635rem //90px
                )
        )
);

/*  ==================================================================
    Get the type scale based on level, device range and type of scaling, i.e font-size or line-height
    ==================================================================  */

@function get-type-scale($level : 1, $device-range : 'small', $type : 'font-size') {
    @return map-get(map-get(map-get($responsive-type-levels, $level), $device-range), $type);
}




/*  ==================================================================
    Mapping between the responsive typography levels and different HTML element groups, for
    null - mobile
    medium - tablet
    large - desktop and above
    ==================================================================  */

$h1-font-sizes: (
        null  : (get-type-scale(4, 'small', 'font-size'), get-type-scale(4, 'small', 'line-height')),
        medium: (get-type-scale(4, 'medium', 'font-size'), get-type-scale(4, 'medium', 'line-height')),
        large : (get-type-scale(4, 'large', 'font-size'), get-type-scale(4, 'large', 'line-height'))
);

$h2-font-sizes: (
        null  : (get-type-scale(3, 'small', 'font-size'), get-type-scale(3, 'small', 'line-height')),
        medium: (get-type-scale(3, 'medium', 'font-size'), get-type-scale(3, 'medium', 'line-height')),
        large : (get-type-scale(3, 'large', 'font-size'), get-type-scale(3, 'large', 'line-height'))
);

$h3-font-sizes: (
        null  : (get-type-scale(2, 'small', 'font-size'), get-type-scale(2, 'small', 'line-height')),
        medium: (get-type-scale(2, 'medium', 'font-size'), get-type-scale(2, 'medium', 'line-height')),
        large : (get-type-scale(2, 'large', 'font-size'), get-type-scale(2, 'large', 'line-height'))
);

$h4-font-sizes: (
        null  : (get-type-scale(1, 'small', 'font-size'), get-type-scale(1, 'small', 'line-height')),
        medium: (get-type-scale(1, 'medium', 'font-size'), get-type-scale(1, 'medium', 'line-height')),
        large : (get-type-scale(1, 'large', 'font-size'), get-type-scale(1, 'large', 'line-height'))
);

$h1-large-font-sizes: (
        null  : (get-type-scale(5, 'small', 'font-size'), get-type-scale(5, 'small', 'line-height')),
        medium: (get-type-scale(5, 'medium', 'font-size'), get-type-scale(5, 'medium', 'line-height')),
        large : (get-type-scale(5, 'large', 'font-size'), get-type-scale(5, 'large', 'line-height'))
);


$p-font-sizes: (
        null  : (get-type-scale(0, 'small', 'font-size'), get-type-scale(0, 'small', 'line-height')),
        medium: (get-type-scale(0, 'medium', 'font-size'), get-type-scale(0, 'medium', 'line-height')),
        large : (get-type-scale(0, 'large', 'font-size'), get-type-scale(0, 'large', 'line-height'))
);


$caption-font-sizes: (
        null  : (get-type-scale(-1, 'small', 'font-size'), get-type-scale(-1, 'small', 'line-height')),
        medium: (get-type-scale(-1, 'medium', 'font-size'), get-type-scale(-1, 'medium', 'line-height')),
        large : (get-type-scale(-1, 'large', 'font-size'), get-type-scale(-1, 'large', 'line-height'))
);

$disclaimer-font-sizes: (
        null  : (get-type-scale(-2, 'small', 'font-size'), get-type-scale(-2, 'small', 'line-height')),
        medium: (get-type-scale(-2, 'medium', 'font-size'), get-type-scale(-2, 'medium', 'line-height')),
        large : (get-type-scale(-2, 'large', 'font-size'), get-type-scale(-2, 'large', 'line-height'))
);


$button-font-sizes: (
        null  : (get-type-scale(1, 'small', 'font-size'), get-type-scale(1, 'small', 'line-height')),
        medium: (get-type-scale(0, 'medium', 'font-size'), get-type-scale(0, 'medium', 'line-height')),
        large : (get-type-scale(0, 'large', 'font-size'), get-type-scale(0, 'large', 'line-height'))
);


/*  ==================================================================
    Generates the actual font-size and line-height selector with the values in the font-sizes lists
    Example: $h1-font-sizes -> large
    ==================================================================  */

@mixin make-font-size($fs-font-size) {
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}

/*  ==================================================================
    Loops through all the breakpoints in the font-sizes map to generate device range specific font-size and line-height
    ==================================================================  */

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        }
        @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}


/* Faces */

    $fontFamily: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;


    /* Weights */

    $fontWeightLight: 300;
    $fontWeightNormal: 400;
    $fontWeightBold: 700;

    @mixin make-font-weight($weight) {
        $weights: (
            1: 100,
            2: 200,
            3: 300,
            4: 400,
            5: 500,
            6: 600,
            7: 700,
            8: 800,
            9: 900
        );

        $output: $weight;
        @if map-has-key($weights, $weight) {
            $output: map-get($weights, $weight);
        }

        font-weight: $output;
    }

    /* Sizes */
    $fontSizeXXSmall: .75rem;
    $fontSizeXSmall: .85rem;
    $fontSizeSmall: 1rem;
    $fontSizeMedium: 1.2rem;
    $fontSizeXMedium: 1.25rem;
    $fontSizeLarge: 1.4rem;
    $fontSizeXLarge: 1.6rem;
    $fontSizeXXLarge: 1.8rem;
    $fontSizeXXXLarge: 2.0rem;



    /* Line Heights */

    $lineHeightXSmall: 1rem;
    $lineHeightSmall: 1.125rem;
    $lineHeightMedium: 1.5rem;
    $lineHeightLarge: 2rem;
    $lineHeightXLarge: 3.125rem;
    $lineHeightXXLarge: 4.1875rem;

    /* Letter Spacings */

    $spacingTighter: -0.01rem;
    $spacingLooser: 0.03rem;


