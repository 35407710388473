/*  ==================================================
    COLLOQUIAL COLOR NAMES
    ==================================================  */

$lightBlue:    #CBEEF5;
$cyan:         #4aa9db;
$primaryBlue:  #1D4596;
$darkBlue:     #09064d;
$brightGreen:  #6ac20a;
$vibrantGreen: #3DCD4B;
$darkGreen:    #54B04B;
$brightRed:    #d0021b;
$mutedBlue:    #0c6797;
$alertOrange:  #f09820;
$alertGreen:   #afebb7;
$lightGrayBlue :   #DBEEF8;
$darkishBlue :#2B84B2;
$cerulean: #00A9CE;

/* Grays and Neutrals */

$darkGray:            #4d4d4d;
$mediumGray:          #979797;
$mediumLightGray:     #ccc;
$mediumLighterGray:   #d8d8d8;
$lightGray:           #F5F5F5;
$whiteLightGray:      #FBFBFB;
$pinkishGray:         #f5dee2;

$black: #000;
$white: #fff;



/*  ==================================================
    Reskin Colors
    ==================================================  */


/*----------  Gray Colors  ----------*/

$grey1: #f8f8f8;
$grey2: #f5f5f5;
$grey3: #e6e6e6;
$grey4: #c6c6c6;
$grey5: #959595; 
$grey6: #818181; //primary
$grey7: #4d4d4d; //text
$grey8: #292929;


/*----------  Orange Colors  ----------*/

$orange00: #fff4eb;
$orange0: #ffe9d6;
$orange1: #ffc5a4;
$orange2: #ffaf82;
$orange3: #fe9a60;
$orange4: #ff843d;
$orange5: #fe7800; //primary
$orange6: #f55400; //secondary


/*----------  Magenta Colors  ----------*/

$pink00:  #feecf5;
$pink0:   #fdd8eb;
$pink1:   #f2abc0;
$pink2:   #ed8eab;
$pink3:   #e97199;
$pink4:   #e54c87;
$pink5:   #e20c77; //primary
$pink6:   #bd0a64; //secondary
$pink7:   #7F0040; //darkest


/*----------  Teal Colors  ----------*/

$teal1: #eefbfb;
$teal2: #def7f7;
$teal3: #2dc2bf;


/*----------  Green Colors  ----------*/

$green1: #fafbee;
$green2: #f1f4cd;
$green3: #c1cf2e;
$green4: #ecfcd9;
$green5: #edfdf1;
$green6: #6fc30d;
$green7: #f5feec;
$green8: #dafbe4;
$green9: #119e3c;

/*----------  Yellow Colors  ----------*/

$yellow1: #fff8eb;
$yellow2: #fff2d7;
$yellow3: #fdb820;


/*----------  Red Colors  ----------*/

$red1: #fdedec;
$red2: #f9dce4;
$red3: #f2635d;
$red4: #d0021b;
$red5: #ad1d43;

/*  ==================================================
    COLOR NAME MAPPINGS
    ==================================================  */
/*
/* Base Color Names */

$colorBaseOne: $cyan;
$colorBaseTwo: $darkBlue;
$colorBaseThree: $darkGray;
$colorBaseFour: $mediumGray;
$colorBaseFive: $white;
$colorBaseSix: $mediumLightGray;
$colorBaseSeven: $mediumLighterGray;
$colorBaseEight: $pinkishGray;
$colorBaseNine: $cerulean;
$colorBaseTen: $darkishBlue;
$colorBaseEleven: $lightBlue;

$colorAttentionOne: $brightGreen;
$colorAttentionTwo: $brightRed;

$colorMutedOne: $lightGray;
$colorMutedTwo: $mutedBlue;
$colorMutedThree: $whiteLightGray;

/* Text Color Names */

$colorTextOne: $darkGray;
$colorTextTwo: $grey6;
$colorTextThree: $mediumGray;
$colorTextFour: $cyan;
$colorTextFive: $darkBlue;
$colorTextSix: $black;
$colorTextSeven: $primaryBlue;
$colorTextEight: $alertOrange;
$colorTextNine: $orange5;
$colorTextTen: $darkishBlue;
$colorTextEleven: $cerulean;
$colorTextTwelve: $whiteLightGray;
$colorTextThirteen: $lightBlue;
$colorTextFourteen: $darkGreen;

/* Background Color Names */

$colorBackgroundOne: $white;
$colorBackgroundTwo: $cyan;
$colorBackgroundThree: $lightGray;
$colorBackgroundFour: $mediumGray;
$colorBackgroundFive: $darkBlue;
$colorBackgroundSix: $darkGray;
$colorBackgroundSeven: $white;
$colorBackgroundEight: $white;
$colorBackgroundNine: $brightGreen;
$colorBackgroundTen: $pink5;
$colorBackgroundEleven: $black;
$colorBackgroundTwelve: $alertOrange;
$colorBackgroundThirteen: $pinkishGray;
$colorBackgroundFourteen: $mediumLighterGray;
$colorBackgroundFifteen: $lightGrayBlue;
$colorBackgroundSixteen: $cerulean;
$colorBackgroundSeventeen: $alertGreen;
$colorBackgroundEighteen: $whiteLightGray;
$colorBackgroundNineteen: $lightBlue;
$colorBackgroundtwenty: $pink6;

/* Border Color Names */

$colorBorderOne: $darkGray;
$colorBorderTwo: $mediumGray;
$colorBorderThree: $darkBlue;
$colorBorderFour: $cyan;
$colorBorderFive: $lightGray;
$colorBorderSix: $white;
$colorBorderSeven: $mediumLightGray;
$colorBorderEight: $darkishBlue;
$colorBorderNine: $cerulean;
$colorBorderTen: $black;
$colorBorderEleven: $alertOrange;
$colorBorderTwelve: $vibrantGreen;
$colorBorderThirteen: $whiteLightGray;

/* Other Color Names */

$colorSuccess: $brightGreen;
$colorError: $brightRed;
