
    @function asset-url($imageUrl){
        @return url($imageUrl);
    }

/*  ==================================================
    Theme Name
    ==================================================  */
    $shared_assets_path: '/bower_components/web-shared-assets/assets';
    /* DEPRECATED THEME PATHS */

    $theme_name_deprecated: 'blue_elephant';
    $theme_path: "shared/themes/blue_elephant/";
    $theme_image_path: "#{$shared_assets_path}/images/shared/themes/blue_elephant/";

    $theme_borrower_image_path: "#{$shared_assets_path}/images/shared/web-borrower/themes/blue_elephant/";
    $theme_investor_image_path: "#{$shared_assets_path}/images/shared/web-investor/themes/blue_elephant/";
    $theme_prosper_image_path: "#{$shared_assets_path}/images/shared/web-prosper/themes/blue_elephant/";

    /* CURRENT THEME PATHS */

    $theme_name_current: "lumberjack";
    $theme_path_current: "shared/themes/lumberjack/";
    $theme_image_path_current: "#{$shared_assets_path}/images/shared/themes/lumberjack/";

    $theme_borrower_image_path_current: "#{$shared_assets_path}/images/shared/web-borrower/themes/lumberjack/";
    $theme_investor_image_path_current: "#{$shared_assets_path}/images/shared/web-investor/themes/lumberjack/";
    $theme_prosper_image_path_current: "#{$shared_assets_path}/images/shared/web-prosper/themes/lumberjack/";



    /* UNIVERSAL THEME PATHS */

    $borrower_image_path: "#{$shared_assets_path}/images/shared/web-borrower/";
    $investor_image_path: "#{$shared_assets_path}/images/shared/web-investor/";
    $prosper_image_path: "#{$shared_assets_path}/shared/web-prosper/";

    $shared_image_path: "#{$shared_assets_path}/images/shared/";

    

/*  ==================================================
    Theme Color (must be above settings.scss)
    ==================================================  */

    @import 'color';
    @import '../../mixins'; //TODO: Need to remove this

/*  ===================================================
    Share Foundation components
    =================================================== */

    @import 'foundation_components_partner_portal';

/*  ==================================================
    Foundation Inclusion (equivalent of app.scss)
    ==================================================  */

    /* CHARSET */

        @charset 'utf-8';

    /* SETTINGS */

        @import 'settings';

/*  ============================================
    SELECTIVELY INCLUDE FOUNDATION COMPONENTS
    ============================================ */

    @include foundation-components;

/*  ==================================================
    Custom SASS Mixins
    ==================================================  */

    @import 'mixins';
    @import 'elements';

/*  ==================================================
    Theme Styles
    ==================================================  */
    @import 'webfonts';
    @import 'typography';

    // Atom mixins
    @import 'atoms/button_mixins';

    // Atoms
    @import 'atoms/buttons';
    @import 'atoms/form_fields';
    @import 'atoms/links';
    @import 'atoms/icons';
    @import 'atoms/lists';
    @import 'atoms/logos';
    @import 'atoms/panels_and_dividers';
    @import 'atoms/typography_elements';

    // Molecules
    @import 'molecules/form_field_groups';
    @import 'molecules/icon_tabs_and_links';
    @import 'molecules/notifications';
    @import 'molecules/tables';
    @import 'molecules/tooltips';
    @import 'molecules/spinner';
    @import 'molecules/custom_bubble';
    @import 'molecules/center_heading';

    // Organisms
    @import 'organisms/datepicker';
    @import 'organisms/header';
    @import 'organisms/footer';
    @import 'organisms/modals_and_dialogs';
    @import 'organisms/activity_feed';
