



.panel-primary{
  border:solid get-color(orange, 6) 1px;
  border-top: none;
  background: get-color(gray, 1);
  @include font-size($p-font-sizes);
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);


  @media #{$medium-up} {
    border-top:solid get-color(orange, 6) 1px;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }

  .panel-header{
    padding: 0;
  }

  .panel-content{
    @include padding-all(1rem, null, 1rem, null);
  }
}

.panel-secondary{
  -webkit-box-shadow: 0.1rem 0.1rem 0.1rem 0rem #818181;
  -moz-box-shadow: 0.1rem 0.1rem 0.1rem 0rem #818181;
  box-shadow: 0.1rem 0.1rem 0.1rem 0rem #818181;
  border-top: 1px solid get-color(gray, 1);
  background: get-color(gray, 1);
  //    @include font-size($p-font-sizes);
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);

  @media #{$medium-up} {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }

  .panel-header{
    padding: 0;
  }

  .panel-content{
    @include padding-all(1rem, null, 1rem, null);
  }
}



.divider{
  @include font-size($caption-font-sizes);
  border: rem-calc(1) solid get-color(gray, 3);
  clear: both;
  margin: 1.25rem -0.9375rem;
  height: 0;
  text-align: center;
  letter-spacing: rem-calc(0.6);
  @media #{$medium-up} {
    @include font-size($p-font-sizes);
    letter-spacing: rem-calc(1);
  }
}

.divider-text{
  color: get-color(gray, 4);
  top: -0.425rem;
  position: relative;
  padding: 0 1rem 0 1rem;
  background: get-color(gray, 2);
  @media #{$medium-up} {
    top: -0.75rem;
  }
}
