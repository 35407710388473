.spinnerContent {
  text-align: center;
  width: 25rem;
  h2 {
    padding: .5rem;
  }

  .info-msg {
    color: $orange5;
    font-size: $fontSizeLarge;
    line-height: $lineHeightLarge;
    font-weight: $fontWeightBold;
    margin-bottom: 4.25rem;
  }

}

.spinner-modal.reveal-modal {
  width: 18rem;
  max-width: 62.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-height: 39vh;
}

.spinnerContent .title {
  color: $orange5;
  margin-top: 1.25rem;
  font-size: $fontSizeLarge;
  line-height: $lineHeightLarge;
  font-weight: $fontWeightBold;
}
.spinnerContent .info-msg {
  color: $orange5;
  font-size: $fontSizeLarge;
  line-height: $lineHeightLarge;
  font-weight: $fontWeightBold;
  margin-bottom: 4.25rem;
}

.spinnerContent ul {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
}

.spinnerContent li {
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  background: asset-url("#{$theme_image_path}icon_check_gray.svg") no-repeat 0;
  background-size: 1.2rem;
  background-position-y: 0;
  text-align: left;
}

.divider {
  border-top: 1px solid $colorBorderSeven;
  margin: 1.56rem -0.9375rem;
}

@media #{$medium-up} {
  .spinner-modal.reveal-modal{
    width: 20rem;
  }
}

@media #{$large-up} {
  .spinner-modal.reveal-modal, dialog {
    // this is necessary to have the modal appear in the correct position
    // and not scroll off the page on desktop
    width: 20rem;
    position: fixed;
    top: 10% !important;
  }
}