/*  ==================================================
    Global Variables
    ==================================================  */

/* Faces */

$fontFamily: "HelveticaNeue", Helvetica, Roboto, Arial, sans-serif;

/* Weights */

$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightBold: 700;

/* Sizes */
$fontSizeXXSmall: .65rem;
$fontSizeXSmall: .75rem;
$fontSizeSmall: .875rem;
$fontSizeMedium: 1rem;
$fontSizeLarge: 1.5rem;
$fontSizeXLarge: 2.25rem;
$fontSizeXXLarge: 3rem;
$fontSizeXXXLarge: 4.19rem;

/* Line Heights */

$lineHeightXSmall: 1.125rem;
$lineHeightSmall: 1.313rem;
$lineHeightMedium: 1.5rem;
$lineHeightLarge: 1.875rem;
$lineHeightXLarge: 2.813rem;
$lineHeightXXLarge: 3.75rem;

/* Letter Spacings */

$spacingTighter: -0.01rem;
$spacingLooser: 0.03rem;

