// Transition
//
// We use this to add transitions to elements
// $property - Default: all, Options: http://www.w3.org/TR/css3-transitions/#animatable-properties
// $speed - Default: 300ms
// $ease - Default:ease-out, Options: http://css-tricks.com/almanac/properties/t/transition-timing-function/
@mixin psp-transition($property:all, $speed:300ms, $ease:ease, $delay:0s) {
  -webkit-transition: $property $speed $ease $delay;
  -moz-transition: $property $speed $ease $delay;
  -o-transition: $property $speed $ease $delay;
  transition: $property $speed $ease $delay;
}

// Border Radius
//
// $radius - Default: 4px
@mixin psp-border-radius($radius:4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
