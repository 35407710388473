


button.primary, button.primary.responsive {

  @include primary-button-responsive();

  &.double-padding {
    padding: 0 6rem;
  }

  &.half-padding {
    padding: 0 1.5rem;
  }

  &.full-width {
    min-width: 100%;
  }

  // static heights, line-heights, and font-sizes
  &.small {
    @include primary-button-small();

    @media #{$medium-up} {
      @include primary-button-small();
    }
    @media #{$large-up} {
      @include primary-button-small();
    }
  }
  &.medium {
    @include primary-button-medium();

    @media #{$medium-up} {
      @include primary-button-medium();
    }
    @media #{$large-up} {
      @include primary-button-medium();
    }
  }

  &.large {
    @include primary-button-large();

    @media #{$medium-up} {
      @include primary-button-large();
    }
    @media #{$large-up} {
      @include primary-button-large();
    }
  }

}

button.secondary, button.secondary.responsive {

  @include secondary-button-responsive();

  &.black {
    @include secondary-button-responsive($color: get-color(gray, 10));
  }

  &.white {
    @include secondary-button-responsive($color: get-color(gray, 1));
  }


  &.double-padding {
    padding: 0 6rem;
  }

  &.half-padding {
    padding: 0 1.5rem;
  }

  &.full-width {
    min-width: 100%;
  }

  // static heights, line-heights, and font-sizes
  &.small {
    @include secondary-button-small();

    @media #{$medium-up} {
      @include secondary-button-small();
    }
    @media #{$large-up} {
      @include secondary-button-small();
    }
  }
  &.medium {
    @include secondary-button-medium();

    @media #{$medium-up} {
      @include secondary-button-medium();
    }
    @media #{$large-up} {
      @include secondary-button-medium();
    }
  }

  &.large {
    @include secondary-button-large();

    @media #{$medium-up} {
      @include secondary-button-large();
    }
    @media #{$large-up} {
      @include secondary-button-large();
    }
  }

}


