/*=================================
  IMPORT ALL FOUNDATION COMPONENTS
  ================================*/
 @import "../../../third_party/foundation6/scss/foundation";

/*=====================
  IMPORT BY COMPONENTS
 ======================*/

/*======================
  Sass utilities
  ======================*/

//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/util/util';

/*==============================
  Global variables and styles
===============================*/

//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/global';

/*=======================
  Components
  =======================*/

//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/grid/grid';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/typography/typography';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/forms/forms';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/visibility';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/float';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/button';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/button-group';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/accordion-menu';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/accordion';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/badge';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/breadcrumbs';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/callout';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/close-button';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/drilldown';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/dropdown-menu';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/dropdown';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/flex';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/flex-video';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/label';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/media-object';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/menu';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/menu-icon';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/off-canvas';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/orbit';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/pagination';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/progress-bar';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/reveal';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/slider';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/sticky';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/switch';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/table';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/tabs';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/title-bar';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/top-bar';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/thumbnail';
//@import '../../../../../../bower_components/web-shared-assets/assets/third_party/foundation6/scss/components/tooltip';
// @import 'motion-ui';


/*==========================================
  SELECTIVELY INCLUDE FOUNDATION COMPONENTS
============================================*/

@mixin foundation-components($flex: false) { // This will not work if you want to use flex-grid. I still try to figure it out what is the issue.
  $global-flexbox: $flex !global;

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @include foundation-flex-classes;
    @include foundation-flex-grid;
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-visibility-classes;
  @include foundation-float-classes;
  //@include foundation-accordion;
  //@include foundation-accordion-menu;
  //@include foundation-badge;
  //@include foundation-breadcrumbs;
  //@include foundation-button-group;
  //@include foundation-callout;
  //@include foundation-close-button;
  //@include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  //@include foundation-flex-video;
  //@include foundation-label;
  //@include foundation-media-object;
  @include foundation-menu;
  @include foundation-menu-icon;
  //@include foundation-off-canvas;
  //@include foundation-orbit;
  //@include foundation-pagination;
  //@include foundation-progress-bar;
  //@include foundation-slider;
  @include foundation-sticky;
  //@include foundation-reveal;
  //@include foundation-switch;
  //@include foundation-table;
  //@include foundation-tabs;
  //@include foundation-thumbnail;
  @include foundation-title-bar;
  //@include foundation-tooltip;
  @include foundation-top-bar;
}
