@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-centerer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-centered-parent{
  position: relative;
}

.vertical-centered{
  @include vertical-centerer;
}



/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
/// Shorthand mixin for absolute positioning
/// Serves as an alias for `position(absolute, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin absolute($args...) {
  @include position(absolute, $args...);
}

/// Shorthand mixin for relative positioning
/// Serves as an alias for `position(relative, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin relative($args...) {
  @include position(relative, $args...);
}

/// Shorthand mixin for fixed positioning
/// Serves as an alias for `position(fixed, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
// Example
//.foo {
//  @include absolute($top: 1em, $left: 50%);
//}


/// Padding short-hand

// Padding longhand version
@mixin padding-all($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

.clear-top-padding{
  @include padding-all(0, null, null, null);
}

.clear-right-padding{
  @include padding-all(null, 0, null, null);
}

.clear-bottom-padding{
  @include padding-all(null, null, 0, null);
}

.clear-left-padding{
  @include padding-all(null, null, null, 0);
}

.clear-top-bottom-padding{
  @include padding-all(0, null, 0, null);
}

.clear-left-right-padding{
  @include padding-all(null, 0, null, 0);
}

/// margin short-hand

// margin longhand version
@mixin margin-all($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

// Vendor prefix


@mixin prefix($map, $vendors: webkit moz ms o) {
  @each $prop, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $prop}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$prop}: #{$value};
  }
}
//
//Example:
//.element {
//  @include prefix((transform: translate(-50%, -50%)), webkit ms);
//}
//
//.other-element {
//  @include prefix((
//          column-count: 3,
//          column-gap: 1em,
//          column-rule: 1px solid silver,
//          column-width: 20em
//  )), webkit moz);
//}

//


.equal-cols {
  display: table;
  width: 100%;
}

@mixin equal-col {
  display: table-cell;
  vertical-align: middle;
}

$j : 1;
@for $i from -18 through -1 {
  $width: percentage(abs($j) / 18);

  .equal-col-#{abs($j)}{
    @include equal-col;
    width: $width;
  }

  $j: $j + 1;

}
@mixin message($color) {
  color: $color;
}

$alert-types: (
    (action        action_white    get-color(orange, 6)    panel)
    (success       check_white     get-color(green, 9)  panel)
    (warning       info_white      get-color(teal, 2)   panel)
    (reminder      reminder_white  get-color(teal, 3)   panel)
    (info          info_white      get-color(orange, 7)    panel)
    (action-s      action_white    get-color(orange, 6)    system)
    (success-s     check_white     get-color(green, 2)  system)
    (warning-s     info_white      get-color(teal, 2)   system)
    (reminder-s    reminder_white  get-color(teal, 3)   system)
    (info-s        info_white      get-color(orange, 7)    system)
) !default;

@each $alert-type in $alert-types {
  $type:  nth($alert-type, 1);
  $icon:  nth($alert-type, 2);
  $color: nth($alert-type, 3);
  $scope: nth($alert-type, 4);

  .alert-#{$type} {
    @include message($color);
  }

  .alert-panel-#{$type}{
    @if $scope == "panel" {
      background: $color;
    }
    @else{
      background: $white;
    }

    .alert-panel-icon{
      @if $scope == "panel" {
        border-right: 1px solid $white;
        background: $color asset-url("#{$theme_image_path_current}#{$icon}.svg") no-repeat center center/50%;
        @media #{$medium-up} {
          background: $color asset-url("#{$theme_image_path_current}#{$icon}.svg") no-repeat center center/40%;
        }
      }
      @else{
        background: $color asset-url("#{$theme_image_path_current}#{$icon}.svg") no-repeat center center/55%;
        @media #{$medium-up} {
          background: $color asset-url("#{$theme_image_path_current}#{$icon}.svg") no-repeat center center/50%;
        }
      }
    }
    .alert-panel-content{
      @if $scope == "panel" {
        padding: 0.75rem 0rem 0.75rem 0 ;
        p {
//          color: get-color("solid", 0);
        }
        h4{
          padding: 0;
//          color: get-color("solid", 0);
        }
      }
      @else{
        background: $white;
        padding: 0.75rem 0rem 0.75rem 0;
        p {
//          color: get-color("solid", 1);
        }
      }
    }
    @if $scope == "panel" {
      .alert-panel-close {
        background: $color asset-url("#{$theme_image_path_current}close_white.svg") no-repeat center center/30%;
        @media #{$small-only} {
          background: $color asset-url("#{$theme_image_path_current}close_white.svg") no-repeat center center/50%;
        }
          cursor: pointer;
      }
    }
  }
}

.arrow_box {
  position: relative;
  background: #E6E6E6;
  border-radius: 5px;
}
.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  right: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #E6E6E6;
  border-width: 3px;
  margin-left: -3px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #E6E6E6;
  border-width: 6px;
  margin-left: -6px;
}
/*----------  Custom - Bubble --------*/

@mixin psp-create-bubble($size: rem-calc(15px),
                     $border-radius: 0px,
                     $bg: get-color(gray, 1),
                     $color: get-color(gray, 1),
                     $nob-position: bottom,
                     $nob-location: 37%,
                     $padding: rem-calc(15px),
                     $border: 0px solid get-color(gray, 1),
                     $font-size: 0.85rem,
                     $font-style: normal) {
  position: relative;
  height: 100%;
  padding: $padding;
  color: $color;
  background-color: $bg;
  border-radius: $border-radius;
  border: $border;
  line-height: 1rem;
  font-style: $font-style;
  font-size: $font-size;
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    display: block;
    z-index: 1;
    @if $nob-position == bottom {
      border-width: $size $size 0;
      bottom: -$size;
      border-color: $bg transparent;
      left: 45%;

    } @else if $nob-position == top {
      border-width: 0 $size $size;
      top: -$size;
      border-color: $bg transparent;
      left: 45%;

    } @else if $nob-position == left {
      border-color: transparent $bg;
      top: $nob_location;
      border-width: $size $size $size 0;
      left: -$size;
    } @else {
      border-color: transparent $bg;
      top: $nob_location;
      border-width: $size 0 $size $size;
      right: -$size;
    }
  }
}


/*----------  Center - Heading  ----------*/

@mixin psp-center-heading-line($bg:get-color(gray, 5),
                                $color:get-color(gray, 5),
                                $left-gap:rem-calc(5px),
                                $right-gap:rem-calc(5px),
                                $padding-top: rem-calc(5px),
                                $padding-bottom: rem-calc(5px),
                                $width:50%,
                                $height:rem-calc(1px)) {

  overflow: hidden;
  text-align: center;
  color: $color;
  padding-bottom: $padding-bottom;
  padding-top: $padding-top;
  &:before, &:after {
    position: relative;
    content: "";
    display: inline-block;
    height: $height;
    vertical-align: middle;
    width: $width;
    background-color: $bg;
  }
  &:before {
    right: $right-gap;
    margin-left: -50%;
  }

  &:after {
    left: $left-gap;
    margin-right: -50%;
  }
}

/*----------  Loading - Spinner  ----------*/

@mixin psp-spinner(
  $height: 5rem,
  $width: 5rem,
  $margin: 27% 50%,
  $border: rem-calc(6px) solid get-color(teal, 2),
  $border-top: rem-calc(6px) solid get-color(teal, 3),
  $border-radius: 100%
){
  height: $height;
  width: $width;
  margin: $margin;
  position: relative;
  animation: rotation .6s infinite linear;
  border: $border;
  border-top: $border-top;
  border-radius: $border-radius;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}



//Chevron Sytling
//=====================
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin chevron($direction:"right", $size: 10px, $width: 2px, $color: get-color(gray,9)) {

  display: block;
  width: $size;
  height: $size;
  border-top: 1px solid $color;
  border-right: 1px solid $color;
  border-width: $width;

  @if $direction == 'top'    { @include rotate(-45deg);}
  @if $direction == 'right'  { @include rotate(45deg);}
  @if $direction == 'bottom' { @include rotate(135deg);}
  @if $direction == 'left'   { @include rotate(225deg);}

}



//Checkbox Button Sytling
//=====================
@mixin checkbox($checkcolor:get-color(magenta,7)){
	position: relative;
	top: -0.375rem;
	cursor: pointer;
	&:before {
    @include prefix((transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75)), webkit moz ms o);
    @include prefix((transform: rotate(-45deg) scale(0, 0)), webkit moz ms o);
		content: "";
		position: absolute;
    left: 0.25rem;
    top: 0.30rem;
		z-index: 1;
		width: 0.75rem;
		height: 0.375rem;
		border: 2px solid $checkcolor;
		border-top-style: none;
		border-right-style: none;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 1.25rem;
		height: 1.25rem;
		background: get-color(gray,1);
		border: 2px solid get-color(gray,5);
		cursor: pointer;
	}
	&:checked:before {
    @include prefix((transform: rotate(-45deg) scale(1, 1)), webkit moz ms o);
	}
}

//Radio Button Sytling
//=====================
@mixin radio($radiocolor:get-color(magenta,7)){
	position: relative;
	cursor: pointer;
	&:before {
    @include prefix((transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75)), webkit moz ms o);
    @include prefix((transform: scale(0, 0)), webkit moz ms o);
		content: "";
		position: absolute;
		top: 0rem;
		left: 0rem;
		z-index: 1;
		width: 0.85rem;
		height: 0.85rem;
		background: $radiocolor;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
	}
	&:after {
		content: "";
		position: absolute;
		top: -0.175rem;
	  left: -0.2rem;
		width: 1.25rem;
		height: 1.25rem;
		background: get-color(gray,1);
		border: 2px solid get-color(gray,5);
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
	}
	&:checked:before {
    @include prefix((transform: scale(1, 1)), webkit moz ms o);
	}
}
