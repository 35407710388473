.footer{
  background-color: get-color(gray,1);
  padding: 5rem 0 5rem 0;
  p{
    margin-bottom: 1rem;
  }
  ul{
    list-style-type: none;
    li{
      padding: 1rem 0;
      a{
        text-decoration: none;
        font-size: $fontSizeMedium;
      }
      a{
        text-decoration: none;
        font-size: $fontSizeMedium;
      }
      &:first-child{
        padding-top: 0;
      }
    }
  }
  @extend .style-icon;
  @extend .group-logo;
}


.footer-professional{
  background-color: get-color(gray,9);
  padding: 5rem 0 5rem 0;
  p{
    margin-bottom: 1rem;
    font-size: $fontSizeXXSmall;
    color: get-color(gray, 7);
  }
  ul{
    list-style-type: none;
    li{
      padding: 1rem 0;
      a{
        text-decoration: none;
        font-size: $fontSizeMedium;
      }
      a{
        text-decoration: none;
        font-size: $fontSizeMedium;
        color: get-color(gray, 5);
        &:hover{
          color:get-color(magenta, 8);
        }
      }
      &:first-child{
        padding-top: 0;
      }
    }
  }
  @extend .style-icon;
  @extend .group-logo;
}

.style-icon {
  .circle-icon {
    padding: 1rem 1.5rem;
    margin: 1.25rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    text-align: center;
    line-height: 4rem;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem  1.5rem;
  }
}

.group-logo {
  .facebook-logo{
    background-image: asset-url("#{$theme_image_path_current}icon_facebook_gray.svg");
  }
  .twitter-logo{
    background-image: asset-url("#{$theme_image_path_current}icon_twitter_gray.svg");
  }
  .linkedin-logo{
    background-image: asset-url("#{$theme_image_path_current}icon_linkedin_gray.svg");
  }
}
