$fa-font-path: "../bower_components/font-awesome/fonts";
@import '../bower_components/font-awesome/scss/fontawesome';
@import "../bower_components/foundation/scss/normalize";
@import "../bower_components/foundation/scss/foundation";

@import "../bower_components/web-shared-assets/assets/stylesheets/themes/lumberjack/partner_portal";


/**
 * App-wide Styles
 */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

// Component styles are injected through grunt
// injector

@import 'colors';

@import 'elements';

@import 'loader';

@import 'login/login';

@import 'logout/logout';

@import 'mixin';

@import 'partner/partner';

@import 'reports/reports';

@import 'saveButton/saveButton';

@import 'typo';

@import 'users/users';

@import '../components/footer/footer';

@import '../components/navbar/navbar';

// endinjector
