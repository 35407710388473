a, a.gray {
  color: get-color(gray, 8);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active, {
    color: get-color(magenta, 7);
  }
}


a.pink {
  color: get-color(magenta, 8);
  text-decoration: none;

  &:hover,
  &:focus,
  &:active, {
    color: get-color(magenta, 7);
    text-decoration: none;
  }
}

